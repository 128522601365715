var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "app-ecommerce-details"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.product === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Error fetching product data ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No item found with this item slug. Check "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-e-commerce-shop'
      }
    }
  }, [_vm._v(" Shop ")]), _vm._v(" for other items. ")], 1)]), _vm.product ? _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-row', {
    staticClass: "my-2"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center mb-2 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('b-img', {
    staticClass: "product-img",
    attrs: {
      "src": _vm.product.image,
      "alt": "Image of ".concat(_vm.product.name),
      "fluid": ""
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.product.name))]), _c('b-card-text', {
    staticClass: "item-company mb-0"
  }, [_c('span', [_vm._v("by")]), _c('b-link', {
    staticClass: "company-name"
  }, [_vm._v(" " + _vm._s(_vm.product.brand) + " ")])], 1), _c('div', {
    staticClass: "ecommerce-details-price d-flex flex-wrap mt-1"
  }, [_c('h4', {
    staticClass: "item-price mr-1"
  }, [_vm._v(" $" + _vm._s(_vm.product.price) + " ")]), _c('ul', {
    staticClass: "unstyled-list list-inline pl-1 border-left"
  }, _vm._l(5, function (star) {
    return _c('li', {
      key: star,
      staticClass: "ratings-list-item mr-25"
    }, [_c('feather-icon', {
      class: [{
        'fill-current': star <= _vm.product.rating
      }, star <= _vm.product.rating ? 'text-warning' : 'text-muted'],
      attrs: {
        "icon": "StarIcon",
        "size": "18"
      }
    })], 1);
  }), 0)]), _c('b-card-text', [_vm._v("Available - "), _c('span', {
    staticClass: "text-success"
  }, [_vm._v("In stock")])]), _c('b-card-text', [_vm._v(_vm._s(_vm.product.description))]), _c('ul', {
    staticClass: "product-features list-unstyled"
  }, [_vm.product.hasFreeShipping ? _c('li', [_c('feather-icon', {
    attrs: {
      "icon": "ShoppingCartIcon"
    }
  }), _c('span', [_vm._v("Free Shipping")])], 1) : _vm._e(), _c('li', [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  }), _c('span', [_vm._v("EMI options available")])], 1)]), _c('hr'), _c('div', {
    staticClass: "product-color-options"
  }, [_c('h6', [_vm._v("Colors")]), _c('ul', {
    staticClass: "list-unstyled mb-0"
  }, _vm._l(_vm.product.colorOptions, function (color) {
    return _c('li', {
      key: color,
      staticClass: "d-inline-block",
      class: {
        'selected': _vm.selectedColor === color
      },
      on: {
        "click": function click($event) {
          _vm.selectedColor = color;
        }
      }
    }, [_c('div', {
      staticClass: "color-option",
      class: "b-".concat(color)
    }, [_c('div', {
      staticClass: "filloption",
      class: "bg-".concat(color)
    })])]);
  }), 0)]), _c('hr'), _c('div', {
    staticClass: "d-flex flex-column flex-sm-row pt-1"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleCartActionClick(_vm.product);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ShoppingCartIcon"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.product.isInCart ? 'View In Cart' : 'Add to Cart'))])], 1), _c('b-button', {
    staticClass: "btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleProductInWishlist(_vm.product);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    class: {
      'text-danger': _vm.product.isInWishlist
    },
    attrs: {
      "icon": "HeartIcon"
    }
  }), _c('span', [_vm._v("Wishlist")])], 1), _c('b-dropdown', {
    staticClass: "btn-share",
    attrs: {
      "variant": "outline-secondary",
      "no-caret": "",
      "toggle-class": "btn-icon",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "Share2Icon"
          }
        })];
      },
      proxy: true
    }], null, false, 4254340102)
  }, _vm._l(['FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon'], function (icon) {
    return _c('b-dropdown-item', {
      key: icon
    }, [_c('feather-icon', {
      attrs: {
        "icon": icon
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('e-commerce-product-details-item-features'), _c('e-commerce-product-details-related-products')], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }